<template>
    <div class="mb-50" v-if="showDetail">
        <div class="list-z">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
                <el-breadcrumb-item :to="{ path: '/' }"
                    >首页</el-breadcrumb-item
                >
                <el-breadcrumb-item class="is-link"
                    v-if="previousRoute && !!previousRoute.value"
                    @click.native="toPreviousRoute"
                    >{{ previousRoute.label }}</el-breadcrumb-item
                >
                <el-breadcrumb-item
                    v-if="(!previousRoute || !previousRoute.value) && previousRoute0 && !!previousRoute0.value"
                    :to="{ path: previousRoute0.value }"
                    >{{ previousRoute0.label }}</el-breadcrumb-item
                >
                <el-breadcrumb-item>人员详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <detail-page v-if="!emptyInfo" v-loading="loadingStatus"
            :cards="cards"
            :title="title"
            @linkAction="onLinkAction"
        />
        <div class="empty-detail" v-else>
            <span>未获取到人员详情数据</span>
        </div>
    </div>
</template>

<script>
import DetailPage from "@/components/detail-page";
import {
    getEnterprisePersonPage,
} from "@/api/aptitude";
export default {
    props: {
        row: {
            type: Object,
            default: () => {},
        },
        previousRoute: {
            type: Object,
            default: () => {},
        }
    },
    components: {
        DetailPage,
    },
    data() {
        return {
            loadingStatus: false,
            previousRoute0: {
                label: "",
                value: "",
            },
            showDetail: false,
            cards: [],
            title: "",
            compCode: "",
            personCode: "",
        };
    },
    computed: {
        emptyInfo() {
            return !this.personCode;
        }
    },
    mounted() {
        this.extractPreviousRoute();
        this.extractPersonCode();
        this.getDetail();
    },
    methods: {
        extractPreviousRoute() {
            const matched = this.$route.matched;
            if (matched.length > 1) {
                const previousRoute = matched[matched.length - 2];
                const previousRouteName = previousRoute.name;
                console.log(
                    "extractPreviousRoute-->previousRouteName=" +
                        previousRouteName
                );
                let label = "",
                    value = "";
                if (previousRouteName == "personnel") {
                    label = "人员查询";
                    value = "/" + previousRouteName + "/index";
                }
                this.previousRoute0.label = label;
                this.previousRoute0.value = value;
            }
        },
        extractPersonCode() {
            const personCode = this.$route.query.code;
            this.personCode = personCode;
        },
        toPreviousRoute() {
            this.showDetail = false;
            this.$emit('close');
        },
        isValidRow() {
            return this.row && this.row.code;
        },
        async getPersonData(personCode) {
            if (this.loadingStatus) return;
            this.loadingStatus = true;
            let record = null;
            let params = {
                size: 20,
                current: 1,
                code: personCode
            };
            console.log("getPersonData-->params=", params);
            try {
                // 获取数据的 API 请求
                const response = await getEnterprisePersonPage(params);
                let records = response.data ? response.data.records : [];
                let total = response.data ? response.data.total : 0;

                record = (Array.isArray(records) && records.length > 0) ? records[0] : null;
            } catch (res) {
                console.error(error);
                this.$message.error("获取人员信息失败");
            } finally {
                this.loadingStatus = false;
            }
            return record;
        },
        jsonDecodeProfs(profs) {
            let ret = profs;
            try {
                let jsonObj = JSON.parse(profs);
                if (jsonObj && Array.isArray(jsonObj)) {
                    ret = jsonObj.join(',');
                }
            } catch(e) {                
            }
            return ret;
        },
        async getDetail() {
            try {
                const item = this.isValidRow() ? this.row : (this.getPersonData(this.personCode) || {});
                this.title = item.name;
                this.compCode = item.compCode;
                this.personCode = item.code;
                console.log("this.personCode=" + this.personCode);
                let profs = item.profs ? (
                    Array.isArray(item.profs) ?  item.profs.join(",") : this.jsonDecodeProfs(item.profs)
                ) : '';
                this.cards = [
                    {
                        colsNum: 2,
                        title: item.name,
                        fields: [
                            {
                                label: "注册证书类型",
                                value: item.typeName,
                            },
                            {
                                label: "注册编号",
                                value: item.code,
                            },
                            {
                                label: "注册专业",
                                value: profs,
                            },
                            {
                                label: "有效期",
                                value: item.edate || '-',
                            },
                            {
                                label: "注册单位",
                                value: item.company,
                                hasLink: true,
                            },
                        ],
                    },
                ];
                this.showDetail = true; // 显示 DetailPage 组件
            } catch (error) {
                console.error(error);
                this.$message.error("获取人员信息失败");
            } finally {
                this.loadingStatus = false;
            }
        },
        onLinkAction(label, value) {
            if (label == '注册单位') {
                console.log('linkAction', label, value);
                this.$router.push({path: "/qualification/detail/", query: { unicode: this.compCode }});
            }
        },
    },
};
</script>
<style scoped>

::v-deep .is-link > .el-breadcrumb__inner {
    color: #303133 !important;
    font-weight: 700;
    text-decoration: none; 
    
}

::v-deep .is-link > .el-breadcrumb__inner:hover {
    color: #409EFF !important;
    cursor: pointer;
}

.list-z {
    text-align: left;
    font-weight: 500;
    color: #4e4e4e;
    line-height: 40px;
    margin-top: 35px;
    margin-bottom: 25px;
}
.fs-18 {
    font-size: 18px;
}
.mb-50 {
    margin-bottom: 50px;
}
.table-header-area {
    display: flex;
}
.table-header {
    width: 520px;
    height: 60px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    text-align: left;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-left: 20px;
}

.table-title {
    width: 233px;
    height: 24px;
    font-size: 24px;
    font-family: Source Han Sans CN-Regular;
    font-weight: 500;
    line-height: 60px;
}

.right-header {
    text-align: right;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-right: 20px;
    flex: 1;
}

::v-deep .el-tabs__nav.is-stretch > * {
    width: auto;
}
::v-deep .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0px 0 30px;
}
::v-deep .detail-card {
    overflow: hidden;
    margin-bottom: 30px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
    flex: 1;
    text-align: center;
    width: auto;
    height: 48px;
    font-size: 20px;
    line-height: 48px;
}
::v-deep .detail-card-label {
    display: table-cell;
    width: 232px;
    font-size: 20px;
    color: #888888;
    padding-top: 16px;
    padding-bottom: 16px;
}
::v-deep .detail-card-value {
    padding-bottom: 15px;
}
::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
    font-size: 18px;
}
::v-deep .detail-card-value {
    font-size: 20px;
}
::v-deep .detail-card-title {
    margin-bottom: 20px;
}
::v-deep .detail-card-divider {
    margin-bottom: 30px;
}
.empty-detail {
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: 16px;
    background: #edf4ff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
}
</style>
