<template>
  <div class="mb-50">
    <div v-show="!showDetail">
      <div class="list-z">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
          <el-breadcrumb-item :to="{ path: '/enterprise/home' }" style="color: #FFFFFF;">首页</el-breadcrumb-item>
          <el-breadcrumb-item>人员查询</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="border-line">
        <el-form
          :model="formData"
          ref="formData"
          label-width="100px"
          label-position="right"
          class="demo-formData"
        >
          <el-row class="distance-top">
            <el-col :span="20">
              <el-form-item label="人员类别：" prop="typeNameInfo">
                <div style="display: flex">
                  <el-cascader
                    style="width: 100%"
                    :props="cascaderProps"
                    v-model="formData.typeNameInfo"
                    :options="typeNames"
                    placeholder="请选择"
                    @change="onTypeNameSelect"
                    size="medium"
                    clearable
                  >
                  </el-cascader>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="9">
              <el-form-item label="注册号：" prop="code">
                <el-input
                  v-model="formData.code"
                  placeholder="请输入注册号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="注册单位：" prop="company">
                <el-input
                  v-model="formData.company"
                  placeholder="请输入注册单位"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-button
                type="primary"
                @click="handleQuery"
                :disabled="isLoadingStatus()"
                >立即查询</el-button
              >
              <el-button style="margin-left: 20px" @click="handleReset"
                >重置</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
      <generic-table
        ref="genericTable"
        :get-data="getPageData"
        :columns="displayColumns"
        :page-size="size"
        :hide-pagination="false"
        :headerCellBkcolor="'#F8F8F8'"
        @linkAction="onLinkAction"
      ></generic-table>
    </div>
    <PersonDetail
      v-if="showDetail"
      :row="showingRow"
      :previousRoute="myRoute"
      @close="onDetailClose"
    ></PersonDetail>
  </div>
</template>

<script>
import GenericTable from "@/components/generic-table";
import PersonDetail from "./detail";

import {
  getEnterprisePersonPage,
  getPersonTypesByCommonCategory,
} from "@/api/aptitude";
export default {
    data() {
        return {
            showDetail: false,
            showingRow: {},
            size: 20,
            current: 1,
            searchValue: "",
            isSearchingName: false,
            typeNames: [],
            myRoute: {
              label: '人员查询',
              value: '/personnel/index'
            },
            cascaderProps: {
                lazy: false,
                value: "name",
                label: "name",
                children: "child",
                expandTrigger: "hover",
                emitPath: true,
                multiple: false,
            },
            searchValue: "",
            displayColumns: [
                {
                    label: "姓名",
                    prop: "name",
                    minWidth: 15,
                    hasLink: true,
                },
                {
                    label: "注册证书类型",
                    prop: "typeName",
                    minWidth: 25,
                },
                { label: "注册专业", prop: "profsName", minWidth: 25 },
                { label: "有效期", prop: "edate", minWidth: 25 },
            ],
            formData: {
                code: "",
                company: "",
                typeNameList: [],
                typeNameInfo: [],
            },
        };  },
  computed: {},
  components: {
    GenericTable,
    PersonDetail,
  },
  mounted() {
    this.getPersonTypes();
    document.title = "人员查询_邦建通";
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        let newVal = to.query ? to.query.name : "";
        this.searchValue = newVal;
       
       
        this.onSearchValueChange();
        if(to.query?.showingRow){
          this.showDetail= true
          this.showingRow= to.query?.showingRow
        }
      },
    },
  },
  created(){
    let code=this.$route.query?.personCode
    if(code){
      this.isSearchingName=false
     console.log('============code',code)
        this.formData.code=code
    }
  },
  methods: {
    isLoadingStatus() {
      return (
        this.isSearchingName ||
        (this.$refs.genericTable && this.$refs.genericTable.isLoadingStatus)
      );
    },
    handleQuery() {
      let formData = this.formData;
      console.log("handleQuery-->formData", formData);
      this.reloadTablePage(1);
    },
    handleReset() {
      console.log("handleReset...");
      this.formData.typeNameList = [];
      if (this.$refs.formData) this.$refs.formData.resetFields();
    },
        onTypeNameSelect(e, noResetBap) {
            console.log("onTypeNameSelect=", e);
            if (e && e.length > 0) {
                let typeNameList = [];
                e.forEach((item, idx) => {
                    if (item) {
                        if (Array.isArray(item))
                            typeNameList.push(item[item.length - 1]);
                        else if (idx)
                            typeNameList.push(item);
                    }
                });
                this.formData.typeNameList = typeNameList;
            } else {
                this.formData.typeNameList = [];
            }
            if (!noResetBap) {
            }
        },
        onSearchValueChange() {
            this.isSearchingName = true;
            this.reloadTablePage(1);
        },
        reloadTablePage(current) {
            this.current = current;
            if (this.$refs.genericTable)
                this.$refs.genericTable.reload(this.current);
        },
        onLinkAction(prop, row) {
            if (prop == "name") {
                console.log("linkAction", prop, row);
                this.showingRow = row;
                this.showDetail = true;
            }
        },
        onDetailClose() {
          this.showingRow = {};
          this.showDetail = false;
        },
        extractTypeNames(records) {
            return (records || []).filter((item) => item.label == "人员类别");
        },
        getPersonTypes() {
            getPersonTypesByCommonCategory({
                appid: "wxbd94b37dd45c1f91",
            }).then((res) => {
                console.log(res.data);
                this.typeNames = this.extractTypeNames(res.data);
            });
        },
        jsonDecodeProfs(profs) {
            let ret = profs;
            try {
                let jsonObj = JSON.parse(profs);
                if (jsonObj && Array.isArray(jsonObj)) {
                    ret = jsonObj.join(',');
                }
            } catch(e) {                
            }
            return ret;
        },
        async getPageData({ size, current }) {
            this.size = size;
            this.current = current;
            let params = {
                size,
                current,
            };
            if (!this.isSearchingName) {
                let { code, company, typeNameList } = this.formData;
                params.code = code;
                params.company = company;
                params.typeNameList = typeNameList;
            } else {
                params.name = this.searchValue;
            }

      console.log("getPageData-->params=", params);
      let records = [];
      let total = 0;
      try {
        // 获取数据的 API 请求
        const response = await getEnterprisePersonPage(params);

        records = response.data ? response.data.records : [];
        total = response.data ? response.data.total : 0;
        (records || []).forEach((item) => {
          item.profsName = item.profs
            ? Array.isArray(item.profs)
              ? item.profs.join(",")
              : this.jsonDecodeProfs(item.profs)
            : "";
        });
      } catch (res) {
      } finally {
        this.isSearchingName = false;
      }
      return {
        data: records,
        total: total,
      };
    },
  },
};
</script>
<style scoped>
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 35px;
  margin-bottom: 20px;
}
.fs-18 {
  font-size: 18px;
}
.mb-50 {
  margin-bottom: 50px;
}
.distance-top {
  margin-bottom: 10px;
  margin-top: 40px;
}
.border-line {
  height: 200px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #dcdcdc;
  text-align: left;
  padding-left: 20px;
}
::v-deep .el-button--primary {
  background-color: #007ef2;
  border-color: #007ef2;
}
</style>
